import React from 'react'
import { Container, Flex, Box, css, Text } from 'theme-ui'
import ListItem from '@solid-ui-components/Educabot/ListItem'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import ContentImages from '@solid-ui-components/Educabot/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  items: {
    display: 'block'
  },
  title: {
    fontSize: 40,
    fontWeight: 600,
    pt: '3rem',
    mb: '1rem',
    color: '#2A205E',
    '@media (max-width: 765px)': {
      fontSize: 30,
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
  subTitle: {
    mt: '-1rem',
    fontSize: '18px',
    pb: '1rem'
  },
  contentTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 991px)': {
      justifyContent: 'center',
      alignItems: 'baseline',
    },
    '@media (max-width: 765px)': {
      justifyContent: 'flex-start',
    },
  },
  contentText: {
    flexBasis: `2/5`,
    textAlign: [`left`, 'left', 'center', `left`],
    '@media (max-width: 765px)': {
      pt: '2rem',
    },
    '@media (max-width: 500px)': {
      textAlign: 'center',
      marginBottom: '1rem'
    },
    '@media (max-width: 545px)': {
      pt: '2rem',
    }
  }
}

const HeroBlock01 = ({
  content: { text = [], images, buttons, collection, icon },
  reverse
}) => (
  <Container>
    <Box sx={{ textAlign: `center`, maxWidth: '100%', pb: '2rem' }}>
      <Text sx={styles.title}> Llevá a tu escuela al próximo nivel </Text>
      <Text sx={styles.subTitle}>Te ofrecemos todo lo necesario para convertir un aula en un espacio maker.</Text>
    </Box>
    <Flex
      sx={{
        alignItems: [null, null, null, `center`],
        flexDirection: [
          reverse ? 'column-reverse' : `column`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ]
      }}
    >
      <Box
        sx={{
          flexBasis: [null, null, null, `3/5`],
          [reverse ? 'ml' : 'mr']: [null, null, null, 5],
          position: `relative`,
          textAlign: `center`
        }}
      >
        <ContentImages
          content={{ images }}
          loading='eager'
          reverse={reverse}
          imagePosition='center'
        />
      </Box>

      <Box sx={styles.contentText}>
        <Reveal effect='fadeInDown'>
          <Box sx={styles.contentText}>
            <ContentText content={text} />
            <Text sx={styles.subTitle}>
              <p>Nuestro programa involucra disciplinas como la <strong>programación</strong> y <strong>robótica</strong> junto a otras actividades pertenecientes a la cultura maker como <strong>impresión 3D, drones, videojuegos y electrónica</strong>.</p>
              <p>Abarcamos cada uno de los ciclos educativos: desde inicial y primario hasta el nivel secundario.</p></Text>
          </Box>
        </Reveal>
        {collection && (
          <>
            <Box sx={styles.items}>
              <Reveal
                effect={reverse ? 'fadeInRight' : 'fadeInLeft'}
                duration={1.5}
              >
                {collection.map((props, index) => (
                  <>
                    <Box sx={{ display: 'flex' }}>
                      <ListItem key={`item-${index}`} {...props} />
                    </Box>
                  </>
                ))}
              </Reveal>
            </Box>
          </>
        )}
        {buttons && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ mb: [4, null, null, 0],
              '@media (max-width: 500px)': {
                textAlign: 'center'
              }
            })}
          >
            {buttons && (
              <>
                <ContentButtons content={buttons} />
              </>
            )}
          </Reveal>
        )}
      </Box>
    </Flex>
  </Container>
)


export default WithDefaultContent(HeroBlock01)
