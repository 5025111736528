/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import bgContact from '../../../../../../site/content/assets/flexiblocks/homepage/education/bg-contact.svg'

export default {
  header: {
    bg: '#27A8FF',
    pt: '5rem',
  },
  heroOne: {
    bg: '#FFFFFF',
    pt: '2rem',
    pb: '5rem',
    '@media (max-width: 991px)': {
      pb: '2rem',
    },
  },
  features: {
    pt: '4rem',
    pb: '5rem'
  },
  contact: {
    bg: '#27A8FF',
    backgroundImage: `url(${bgContact})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center`,
    backgroundSize: 'contain',
    '@media (max-width: 765px)': {
      backgroundSize: 'cover',
      paddingBottom: '3rem',
    },
    '@media (max-width: 625px)': {
       paddingBottom: '8rem',
     },
     '@media (max-width: 485px)': {
       paddingBottom: '11rem',
     }
  },
  books: {
    bg: '#FFFFFF'
  },
  sections: {
    pt: '4rem',
    bg: '#FFFFFF'
  },
  robotic: {
    pt: '5rem',
    pb: '5rem',
    bg: '#FFFFFF'
  },
  pictures: {
    pb: '3rem'
  },
  title: {
    fontSize: 40,
    fontWeight: 600,
    pt: '3rem',
    mb: '1rem',
    color: '#2A205E',
    '@media (max-width: 765px)': {
      fontSize: 30,
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
  subTitle: {
    mt: '-1rem',
    fontSize: '18px',
    pb: '1rem'
  },
}
