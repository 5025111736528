import React from 'react'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import theme from './_theme'
import styles from './_styles'

import { Container, Box, Text } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01/Block01'
import ModalContactAulaMaker from '@solid-ui-blocks/Educabot/AulaMaker/Modal/Block01/Block01'
import Header from '@solid-ui-blocks/Educabot/Header/header'
import HeroOne from '@solid-ui-blocks/Educabot/AulaMaker/Hero/Block03'
import Features from '@solid-ui-blocks/Educabot/AulaMaker/Features/features'
import Contact from '@solid-ui-blocks/Educabot/AulaMaker/Contact'
import Tabs from '@solid-ui-components/AulaMaker/Tabs'
import Screenshot from '@solid-ui-blocks/Educabot/AulaMaker/FeaturesWithPhoto/Block03'
import Posts from '@solid-ui-blocks/Educabot/Blog/Block01'
import favicon from '../../../../../../site/content/assets/favicon.png'


import Pedagogic from '@solid-ui-blocks/Educabot/AulaMaker/Hero/books/Block05'
import Equipment from '@solid-ui-blocks/Educabot/AulaMaker/Hero/equipment/Block05'
import Platform from '@solid-ui-blocks/Educabot/AulaMaker/Hero/platform/Block05'
import Footer from '@solid-ui-blocks/Educabot/Footer/Block01'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='EDUCABOT' />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      <ModalContactAulaMaker content={content['contact-aula-maker']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Box sx={styles.heroOne}>
        <HeroOne content={content['heroOneAula']} reverse />
      </Box>
      <Box sx={styles.features}>
        <Features content={content['what-is-aula-maker']} />
      </Box>
      <Box sx={styles.books}>
        <Pedagogic content={content['pedagogic']} />
      </Box>
      <Box sx={styles.sections}>
        <Equipment content={content['equipment']} reverse />
      </Box>
      <Box sx={styles.robotic}>
        <Platform content={content['platform']} />
      </Box>
      <Container sx={styles.pictures}>
        <Box sx={{ textAlign: `center`, maxWidth: '100%' }}>
          <Text sx={styles.title}> Una nueva generación de creadores </Text>
          <Text sx={styles.subTitle}>En Aula Maker generamos un ambiente ludico y flexible diseñado para potenciar la creatividad
            en niños y adolescentes.</Text>
        </Box>
        <Tabs space={3} variant='dots' position='bottom' arrows>
          <Screenshot content={content['screenshot-one-aula']} />
          <Screenshot content={content['screenshot-two-aula']} />
          <Screenshot content={content['screenshot-three-aula']} />
          <Screenshot content={content['screenshot-four-aula']} />
          <Screenshot content={content['screenshot-five-aula']} />
          <Screenshot content={content['screenshot-six-aula']} />
        </Tabs>
      </Container>
      <Box sx={styles.contact}>
        <Contact content={content['formContactSolutions']} reverse />
      </Box>
      <Footer content={content['footer']} />


    </Layout>
  )
}

export const query = graphql`
  query solutionsAulaMakerBlockContent {
    allBlockContent(
      filter: { page: { in: ["homepage/educabot", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
